import React from "react"
import styled from "styled-components"
import dompurify from "dompurify"

const BalanceCheckInstructionSection = styled.section`
  text-align: center;
  width: 550px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 1rem;
  }
  a {
    font-family: "OpenSans-Regular";
    font-size: 0.95rem;
    text-decoration: underline;
    color: var(--button-color);
    &:hover {
      color: var(--button-color);
    }
  }
`
const BalanceCheckHeader = styled.h2`
  font-family: "FuturaPT-Medium";
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
`
export default function BalanceCheckInstructions(props) {
  return (
    <BalanceCheckInstructionSection>
      <BalanceCheckHeader>Balance Check</BalanceCheckHeader>
      <p
        className="balance-check-instruction__content"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            props.giftcard ? props.giftcard.balance_check_instructions : "",
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      ></p>
    </BalanceCheckInstructionSection>
  )
}

const StarBalanceCheckInstructionSection = styled.section`
  text-align: center;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 1rem;
  }
  a {
    font-family: "CamptonLight";
    font-size: 0.95rem;
    text-decoration: underline;
    color: var(--button-color);
    &:hover {
      color: var(--button-color);
    }
  }
  p {
    font-family: "CamptonLight";
  }
`
const StarBalanceCheckHeader = styled.h2`
  font-family: "CamptonLight";
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
`

export function StarBalanceCheckInstructionsVersion2(props) {
  return (
    <StarBalanceCheckInstructionSection>
      <StarBalanceCheckHeader>Balance Check</StarBalanceCheckHeader>
      <p
        className="balance-check-instruction__content"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            props.giftcard ? props.giftcard.balance_check_instructions : "",
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      ></p>
    </StarBalanceCheckInstructionSection>
  )
}
