import React from "react"
import styled from "styled-components"
import dompurify from "dompurify"

const RedemptionInstructionSection = styled.section`
  text-align: center;
  a {
    font-family: "OpenSans-Regular";
    font-size: 0.95rem;
    text-decoration: underline;
    color: var(--button-color);
  }
`
const RedemptionHeader = styled.h6`
  font-family: "OpenSans-Bold";
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
`
export default function RedemptionInstructions(props) {
  return (
    <RedemptionInstructionSection>
      <RedemptionHeader>Redemption Instructions</RedemptionHeader>
      <p
        className="redemption-instruction__content"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            props.giftcard ? props.giftcard.redemption_instructions : "",
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      ></p>
    </RedemptionInstructionSection>
  )
}

const RedemptionInstructionSectionV2 = styled.section`
  text-align: center;
  width: 550px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 1rem;
  }
`
const RedemptionHeaderV2 = styled.h2`
  font-family: "FuturaPT-Medium";
`
const RedemptionInstructionsContent = styled.div``

export function RedemptionInstructionsVersion2(props) {
  return (
    <RedemptionInstructionSectionV2>
      <RedemptionHeaderV2>Redemption Instructions</RedemptionHeaderV2>
      <RedemptionInstructionsContent
        className="redemption-instruction__content"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            props.giftcard ? props.giftcard.redemption_instructions : "",
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      ></RedemptionInstructionsContent>
    </RedemptionInstructionSectionV2>
  )
}

const StarRedemptionInstructionSectionV2 = styled.section`
  text-align: center;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 1rem;
  }
`
const StarRedemptionHeaderV2 = styled.h2`
  font-family: "CamptonLight";
`
const StarRedemptionInstructionsContent = styled.p`
  font-family: "CamptonLight";
  font-size: 1rem;
  margin: 0;
  & * {
    margin: 0;
  }
`

export function StarRedemptionInstructionsVersion2(props) {
  return (
    <StarRedemptionInstructionSectionV2>
      <StarRedemptionHeaderV2>Redemption Instructions</StarRedemptionHeaderV2>
      <StarRedemptionInstructionsContent
        className="redemption-instruction__content"
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            props.giftcard ? props.giftcard.redemption_instructions : "",
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      ></StarRedemptionInstructionsContent>
    </StarRedemptionInstructionSectionV2>
  )
}
